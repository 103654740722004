.ButtonsContainer {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
}

.ButtonItem {
    width: 200px;
    height: 150px;
    margin-top: 10px;
    line-height: 150px;
}
