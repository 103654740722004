.KeyGenerator {
    padding: 0;
}

    .KeyGenerator p {
        padding: 20px 0 0 0;
    }

.Button {
    margin: 15px 0;
}

.Alert {
    margin: 15px 0;
}
