.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
}

.Content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
}

.Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
}

.Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
}

.Title {
    margin-bottom: 32px;
    color: #555;
}

.Filename {
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
}

.Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

.CheckIcon {
    opacity: 0.5;
    margin-left: 32px;
}

.ProgressWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.error {
    font-size: 16px;
    color: red;
    font-weight: 600;
}

button {
    font-family: "Roboto medium", sans-serif;
    font-size: 14px;
    display: inline-block;
    height: 36px;
    min-width: 88px;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    background: rgba(103, 58, 183, 1);
    color: #fff;
    outline: 0;
}

    button:disabled {
        background: rgb(189, 189, 189);
        cursor: default;
    }
