.Dropzone {
    height: 300px;
    width: 300px;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

    .Dropzone:disabled {
        opacity: 0.5;
    }

.Highlight {
    background-color: rgb(188, 185, 236);
}

.Icon {
    opacity: 0.3;
    height: 128px;
    width: 128px;
}

.FileInput {
    display: none;
}
