.Logo {
    background-color: white;
    padding: 2px;
    height: 35px;
    box-sizing: border-box;
}

.Logo img {
    height: 100%;
}

    .Logo span {
        margin: 2px 10px;
        font-style:oblique;
    }
